@import "../../node_modules/ladda/css/ladda";
@import "bootstrap/dist/css/bootstrap.min.css";

$m-nav: 70px;
$side-nav-width: 250px;
$side-nav-items-padding: 15px;
$svg-path: "/assets/images/svg";
$content-padding-top: 20px;

/*colors*/
$orange: #FF6B00;
$disabled: #cecece;
$dark-grey: #808080;
$theme-grey: #eeece8;
$charcoal: #393939;
$headergrey: #e4e0dd;
$silverchalice: #a4a4a4;

body {
  margin: 0;
  background-color: $theme-grey;
  font-family: "Lato", sans-serif;
}

.form-row label {
  font-weight: 600;
}

.brand-wrapper {
  height: $m-nav;
  line-height: $m-nav;
  padding-left: 15px;
  display: inline-block;

  .wv-brand {
    background: url(#{$svg-path}/ic-logo-sm.svg) center center no-repeat;
    background-size: contain;
    width: 103px;
    height: 22px;
    display: inline-block;

    @media (min-width: 992px) {
      width: 158px;
      height: 34px;
      margin-top: 14px;
    }
  }
}

header {
  height: $m-nav;
  position: fixed !important;
  background-color: $headergrey;
  top: 0;
  left: 0;
  right: 0;
  z-index: 60;
  margin-bottom: 0;
  box-shadow: 0 1px 3px 0 rgba(#393939, .2);
  margin-bottom: 0 !important;
}

.main {
  margin-top: $content-padding-top;
}

.side-nav-gutter {
  margin-left: $side-nav-width;
}

.wrapper {
  height: auto;
  margin-top: 70px;
}

.sidenav {
  height: 100%;
  width: $side-nav-width;
  position: fixed;
  z-index: 1;
  left: 0;
  background-color: $charcoal;
  overflow-x: hidden;
  padding-top: $content-padding-top;
  padding-bottom: 100px;
  padding-left: $side-nav-items-padding;
  padding-right: $side-nav-items-padding;

  .nav-header {
    font-size: 18px;
    font-weight: 900;
    color: white;
  }

  .dropdown {
    top: 20px;
  }

  .dropdown-menu {
    max-width: $side-nav-width - 10px;
    margin-bottom: 100px;
  }

  a {
    padding: 6px 0 6px 0;
    text-decoration: none;
    font-size: 16px;
    color: white;
    display: block;

    &:hover {
      color: $orange;
    }
  }

  .active {
    color: $orange;
  }

  hr {
    border-top: 1px solid white;
  }

  .options-dropdown {
    padding-bottom: 280px;
  }
}

.container {
  margin-left: 20px;
  padding-top: $content-padding-top;
}

.btn-s {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 600;
  background-color: lightgrey;

  &:hover {
    background-color: darken(lightgrey, 10%);
  }
}

/* loader */
.loader {
  margin: 100px auto;
  width: 20px;
  height: 20px;
  position: relative;
}

.cube1, .cube2 {
  background-color: rgba($dark-grey,0.5);
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  animation-delay: -0.9s;
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(22px) rotate(-90deg) scale(0.5);
  }

  50% {
    transform: translateX(22px) translateY(22px) rotate(-179deg);
  }

  50.1% {
    transform: translateX(22px) translateY(22px) rotate(-180deg);
  }

  75% {
    transform: translateX(0px) translateY(22px) rotate(-270deg) scale(0.5);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.sign-out {
  margin: 15px;

  .btn-sign-out {
    border: none;
    color: black;
    font-weight: 900;
    cursor: pointer;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    background-color: #e4e0dd;
  }
}

.btn-p {
  background-color: $orange;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  border: 0;
  color: white;

  &:hover,
  &:focus,
  &:active,
  &.selected {
    background-color: darken($orange, 5%) !important;
    border-color: darken($orange, 5%) !important;
    box-shadow: none;
    outline: none !important;
    color: white;
  }

  &.ladda-button {
    &:hover,
    &:focus,
    &:active,
    &.selected {
      background-color: darken($orange, 5%) !important;
      border-color: darken($orange, 5%) !important;
      box-shadow: none;
      outline: none;
    }
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: pointer;
    background-color: $disabled !important;
    border-color: $disabled !important;
    opacity: 1;

    &:hover,
    &:focus,
    &:active,
    &.selected {
      cursor: pointer;
      background-color: $disabled !important;
      border-color: $disabled !important;
    }
  }
}

.navbar {
  display: inline-block;
}

.invalid-feedback {
  display: block;
}

/*Sign-in page*/
.sign-in {
  max-width: 600px;
  margin: 10% 35%;

  .invalid-feedback {
    display: block;
    margin-top: -1rem;
  }
}

.container-fluid {
  display: contents;
}

.action-group {
  width: 66%;
}

.pull-right {
  float: right;
}

/*Supporter page*/
.supporter-search {

  wv-field-helper {
    width: 100%;

    .invalid-feedback {
      margin-left: 10px;
    }
  }
}

table {

  caption {
    caption-side: top;
  }

  caption {
    caption-side: top;
  }

  tbody {
    font-weight: normal;

    tr {
      &:hover {
        background-color: lighten($dark-grey, 35%) !important;
      }
    }
  }

  &.table-loading {
    opacity: 50%;
  }
}

.clickable {
  cursor: pointer;
}

.btn-link {
  border: none;
  cursor: pointer;
}

.inline-block {
  display: inline-block;
}

.search-results {
  margin-top: 0px;
  width: 100%;

  th {
    padding-right: 10px;
  }

  tbody {
    tr {
      color: $orange;

      &:hover {
        background-color: lighten($dark-grey, 35%) !important;
        color: darken($orange, 5%) !important;
      }
    }
  }
}

button.calendar {
  width: 2.75rem;
  background-image: url(#{$svg-path}/icon-event.svg);
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
}

.preview-email-template {
  margin: 0 30% 0 30%;
  overflow: hidden;
}

.email-preview-title {
  padding: 2% 30% 0% 30%;
  text-align: center;
}


.accordion-nav {
  .card {
    background-color: transparent;
    border-radius: 0;
    border: 0;

    > .list-group:first-child {
      .list-group-item:first-child {
        border-radius: 0;
      }
    }

    > .list-group:last-child {
      .list-group-item:last-child {
        border-radius: 0;
      }
    }
  }

  .card-header {
    padding: 0;
    background-color: transparent;
    border-bottom: 0;
    margin-bottom: 5px;

    &:first-child {
      border-radius: 0;
    }

    .btn-link {
      color: white;
      width: 100%;
      text-align: left;
      font-size: 1rem;
      -webkit-appearance: none;
      font-weight: 600;
      padding-left: 0;
      padding-right: 0;

      &:hover,
      &:focus,
      &:visited {
        text-decoration: none;
        color: white;
      }

      &.active {
        color: $orange;

        &:hover,
        &:focus,
        &:visited {
          text-decoration: none;
          color: $orange;
        }
      }
    }

    .btn-icn {
      position: relative;

      &:after {
        content: "+";
        position: absolute;
        right: 10px;
        top: 7px;
        display: block;
        color: white;
      }

      &[aria-expanded="true"] {
        &:after {
          content: "-";
          top: 5px;
          color: white;
        }
      }
    }
  }

  .card-body {
    padding: 0.25rem 0.75rem;
    color: $silverchalice;

    .nav {
      border-left: 2px solid $silverchalice;

      .nav-item {
        .nav-link {
          color: $silverchalice;
          padding: 0 1rem 0.5rem 1rem;
          font-size: 14px;
          font-weight: 600;

          &.active {
            color: $orange;
          }
        }

        &:last-of-type {
          .nav-link {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}

#panel-orders {
  height: auto;
  overflow: auto;
}
